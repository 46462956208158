import type { Router } from 'vue-router'
import { defineStore } from '@/modules/pinia-hot'

import {
  notificationsCountResource,
  notificationsListResource,
} from '@/services/notifications.service'

const id = 'notifications'

export const useNotifications = defineStore(id, {
  state: () => ({
    unreadCount: '',
    allList: '',
  }),
  getters: {
  },
  actions: {
    async getUnread() {
      const data = await notificationsCountResource.execute({})
      this.unreadCount = data[0]?.flags['73232']
      console.log("🚀 ~ file: notifications.ts:21 ~ getUnread ~ item:", this.unreadCount)
    },
  },
})
