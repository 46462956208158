import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "tt-header" }
const _hoisted_2 = { class: "tt-header__page-title-wrapper tt-header__page-title-wrapper--auto" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = {
  key: 0,
  class: "tt-header__breadcrumbs"
}
const _hoisted_5 = { class: "tt-header__element" }
const _hoisted_6 = { class: "tt-header__language" }
const _hoisted_7 = { class: "tt-header__icons-block" }
const _hoisted_8 = { class: "tt-header__theme" }
const _hoisted_9 = { class: "tt-header__notifications" }
const _hoisted_10 = { class: "tt-header__fullscreen" }
const _hoisted_11 = { class: "tt-header__userbar-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_i_arrow_left = _resolveComponent("i-arrow-left")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_breadcrumbs = _resolveComponent("breadcrumbs")!
  const _component_language_selector = _resolveComponent("language-selector")!
  const _component_theme_toggler = _resolveComponent("theme-toggler")!
  const _component_notify_bar = _resolveComponent("notify-bar")!
  const _component_fullscreen_toggler = _resolveComponent("fullscreen-toggler")!
  const _component_user_bar = _resolveComponent("user-bar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($setup.route.meta.hasBack)
      ? (_openBlock(), _createBlock(_component_router_link, {
          key: 0,
          class: "tt-header__return-button",
          to: { name: $setup.route.meta.back }
        }, {
          default: _withCtx(() => [
            _createVNode(_component_i_arrow_left, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.languages), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["to"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", {
        class: "tt-header__page-title",
        innerHTML: $setup.currentTitle || $setup.breadcrumbsLabels[$setup.pageTitle]
      }, null, 8, _hoisted_3),
      (!$setup.route.meta.withoutBreadcrumbs)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(_component_breadcrumbs)
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_language_selector)
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_component_theme_toggler)
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createVNode(_component_notify_bar)
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createVNode(_component_fullscreen_toggler)
        ])
      ]),
      _createElementVNode("div", _hoisted_11, [
        _createVNode(_component_user_bar)
      ])
    ])
  ]))
}