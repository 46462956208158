import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "g-row g-row--appearance_spaced g-row--space_sm g-row--space_xl-xl g-row--align_stretch g-row--justify_space-between" }
const _hoisted_2 = {
  key: 0,
  class: "g-cell g-cols g-cols--auto order-first flex for_search"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_d_control_input = _resolveComponent("d-control-input")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.list, (field) => {
      return (_openBlock(), _createElementBlock(_Fragment, {
        key: field.id
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(["g-cell relative", field.type == $setup.s ? 'g-cols--10 order-first for_search' : 'g-cols--4'])
        }, [
          _createVNode(_component_d_control_input, _mergeProps({
            "input-label": field.caption,
            name: field.id,
            options: field.props.items || [],
            ref_for: true,
            ref: field.id,
            type: $setup.options[field.type].type,
            placeholder: field.placeholder || ''
          }, $setup.options[field.type], {
            modelValue: $setup.form[field.id],
            "onUpdate:modelValue": [($event: any) => (($setup.form[field.id]) = $event), ($event: any) => (field.type == 'multiselect' ? () => {} : $setup.getSearch(null, field))],
            onClose: ($event: any) => ($setup.getSearch(null, field)),
            onRemove: ($event: any) => ($setup.getSearch(null, field))
          }), null, 16, ["input-label", "name", "options", "type", "placeholder", "modelValue", "onUpdate:modelValue", "onClose", "onRemove"])
        ], 2),
        (field.type == $setup.s)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _renderSlot(_ctx.$slots, "default")
            ]))
          : _createCommentVNode("", true)
      ], 64))
    }), 128))
  ]))
}