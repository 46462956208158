import { RouteRecordRaw } from 'vue-router';
import { route } from '@/router/utils'
import { allPermissions } from '@access/index'
import { accessPermission } from '@access/permissions'

const {
  STAFF: {
    USER: {
      VIEW
    },
  },
} = allPermissions

const profileView = () => import('./views/View.vue')

export const PROFILE_PAGE_NAME = 'cabinet:profile'

// URLs
export const PROFILE_PAGE_URL = ''

export function createRoutes(): RouteRecordRaw[] {
  return [
    route(PROFILE_PAGE_URL, profileView, PROFILE_PAGE_NAME, {
      meta: {
        breadcrumb: 'profile',
        ...accessPermission(VIEW),
        isMain: true, withoutBreadcrumbs: true,
      }
    }),
  ]
}
