<template>
<svg width="22" height="22" viewBox="0 0 22 22" class="filled" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.78594 13.87C8.61969 13.87 8.45344 13.8087 8.32219 13.6775C7.60469 12.96 7.21094 12.0062 7.21094 11C7.21094 8.90874 8.90844 7.21124 10.9997 7.21124C12.0059 7.21124 12.9597 7.60499 13.6772 8.32249C13.7997 8.44499 13.8697 8.61124 13.8697 8.78624C13.8697 8.96124 13.7997 9.12749 13.6772 9.24999L9.24969 13.6775C9.11844 13.8087 8.95219 13.87 8.78594 13.87ZM10.9997 8.52374C9.63469 8.52374 8.52344 9.63499 8.52344 11C8.52344 11.4375 8.63719 11.8575 8.84719 12.225L12.2247 8.84749C11.8572 8.63749 11.4372 8.52374 10.9997 8.52374Z" fill="black"/>
<path d="M5.39937 16.6962C5.25063 16.6962 5.09313 16.6437 4.97063 16.5387C4.03438 15.7425 3.19438 14.7625 2.47688 13.625C1.54937 12.1812 1.54937 9.82748 2.47688 8.37498C4.61188 5.03248 7.71813 3.10748 10.9994 3.10748C12.9244 3.10748 14.8231 3.77248 16.4856 5.02373C16.7744 5.24248 16.8356 5.65373 16.6169 5.94248C16.3981 6.23123 15.9869 6.29248 15.6981 6.07373C14.2631 4.98873 12.6356 4.41998 10.9994 4.41998C8.17313 4.41998 5.46938 6.11748 3.57938 9.08373C2.92312 10.1075 2.92312 11.8925 3.57938 12.9162C4.23563 13.94 4.98813 14.8237 5.81938 15.5412C6.09063 15.7775 6.12563 16.1887 5.88938 16.4687C5.76688 16.6175 5.58313 16.6962 5.39937 16.6962Z" fill="black"/>
<path d="M10.9993 18.8925C9.83551 18.8925 8.69801 18.6562 7.60426 18.1925C7.27176 18.0525 7.11426 17.6675 7.25426 17.335C7.39426 17.0025 7.77926 16.845 8.11176 16.985C9.03926 17.3787 10.0105 17.58 10.9905 17.58C13.8168 17.58 16.5205 15.8825 18.4105 12.9162C19.0668 11.8925 19.0668 10.1075 18.4105 9.08375C18.1393 8.655 17.8418 8.24375 17.5268 7.85875C17.2993 7.57875 17.343 7.1675 17.623 6.93125C17.903 6.70375 18.3143 6.73875 18.5505 7.0275C18.8918 7.4475 19.2243 7.9025 19.5218 8.375C20.4493 9.81875 20.4493 12.1725 19.5218 13.625C17.3868 16.9675 14.2805 18.8925 10.9993 18.8925Z" fill="black"/>
<path d="M11.6031 14.7362C11.2969 14.7362 11.0169 14.5175 10.9556 14.2025C10.8856 13.8437 11.1219 13.5025 11.4806 13.4412C12.4431 13.2662 13.2481 12.4612 13.4231 11.4987C13.4931 11.14 13.8344 10.9125 14.1931 10.9737C14.5519 11.0437 14.7881 11.385 14.7181 11.7437C14.4381 13.2575 13.2306 14.4562 11.7256 14.7362C11.6819 14.7275 11.6469 14.7362 11.6031 14.7362Z" fill="black"/>
<path d="M2.24977 20.4063C2.08352 20.4063 1.91727 20.345 1.78602 20.2138C1.53227 19.96 1.53227 19.54 1.78602 19.2863L8.32226 12.75C8.57601 12.4963 8.99602 12.4963 9.24977 12.75C9.50352 13.0038 9.50352 13.4238 9.24977 13.6775L2.71352 20.2138C2.58227 20.345 2.41602 20.4063 2.24977 20.4063Z" fill="black"/>
<path d="M13.2146 9.44251C13.0484 9.44251 12.8821 9.38126 12.7509 9.25001C12.4971 8.99626 12.4971 8.57626 12.7509 8.32251L19.2871 1.78626C19.5409 1.53251 19.9609 1.53251 20.2146 1.78626C20.4684 2.04001 20.4684 2.46001 20.2146 2.71376L13.6784 9.25001C13.5471 9.38126 13.3809 9.44251 13.2146 9.44251Z" fill="black"/>
</svg>

</template>
  