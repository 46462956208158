/*
  eslint-disable
    max-classes-per-file,
    prefer-spread,
    prefer-rest-params,
    class-methods-use-this,
    prefer-object-spread
*/
import { isRef, unref } from 'vue'
import { Permission } from '@aspectus/permissions';

export function waitForUser(
  state,
  getter,
  timeout = 5000,
  tick = 5
) {
  return new Promise((resolve, reject) => {
    const maxAttempts = tick
    let attempts = 0

    const interval = setInterval(() => {
      const userState = getter(state)
      if (userState) {
        let { user } = userState
        
        if (!user) {
          clearInterval(interval)
          reject(false)
          return
        }
  
        if (isRef(user)) {
          user = unref(user)
        }
        if (user.determined) {
          clearInterval(interval)
          if (user.isAuthenticated()) {
            resolve(user)
          } else {
            reject()
          }
          return
        }

      }

      attempts++
      if (attempts >= maxAttempts) {
        clearInterval(interval)
        reject()
      }
    }, tick)

    // setTimeout(() => {
    //   clearInterval(interval)
    // }, timeout)
  })
}

export class UserCheckerPermissionBase extends Permission {
  constructor({ getter, timeout = 5000, tick = 5 } = {}) {
    super();

    this.getter = getter;
    this.timeout = timeout;
    this.tick = tick;
  }

  getStore(vm, { pinia } = {}) {
    return vm && vm.$pinia || pinia;
  }

  waitForUser(vm, args) {
    const store = this.getStore(vm, args[0] || {});
    return waitForUser(
      store, this.getter, this.timeout, this.tick
    );
  }
  
  checkUser() {
    return false;
  }

  onHasAccess(vm, ...args) {
    return this.waitForUser(vm, args).then(user => {
      if (!this.checkUser(user, vm, ...args)) {
        throw new Error('Access denied')
      }
    }, e => {
      console.log(e);
    });
  }
}

export class UserCheckerPermission extends UserCheckerPermissionBase {
  constructor({ checker, ...rest }) {
    super(rest);
    this.checker = checker;
  }

  checkUser() {
    return this.checker.apply(null, arguments);
  }
}

export function checkAuth(checker, config = {}, Base = UserCheckerPermission) {
  return new Base(Object.assign({ checker }, config));
}
