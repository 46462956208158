import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "tt-theme-dark-toggler" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_i_fullscreen_on = _resolveComponent("i-fullscreen-on")!
  const _component_i_fullscreen_off = _resolveComponent("i-fullscreen-off")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "theme-icon-stroke tt-theme-dark-toggler",
      onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.toggleFullscreen()))
    }, [
      ($setup.isFullscreen)
        ? (_openBlock(), _createBlock(_component_i_fullscreen_on, { key: 0 }))
        : (_openBlock(), _createBlock(_component_i_fullscreen_off, { key: 1 }))
    ])
  ]))
}