import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "tt-cabinet" }
const _hoisted_2 = { class: "tt-cabinet__sidebar" }
const _hoisted_3 = { class: "tt-cabinet__main" }
const _hoisted_4 = { class: "tt-cabinet__header" }
const _hoisted_5 = { class: "tt-cabinet__content relative" }
const _hoisted_6 = { class: "tt-cabinet__content-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sidebar = _resolveComponent("sidebar")!
  const _component_main_header = _resolveComponent("main-header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_auth_provider = _resolveComponent("auth-provider")!

  return (_openBlock(), _createBlock(_component_auth_provider, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_sidebar)
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_main_header)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_router_view)
            ])
          ])
        ])
      ])
    ]),
    _: 1
  }))
}