import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "tt-icon-tooltip" }
const _hoisted_2 = { class: "tt-icon-tooltip__wrapper" }
const _hoisted_3 = { class: "tt-icon-tooltip__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["tt-icon-tooltip__tooltip", `tooltip--${$setup.props.position}`])
    }, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", null, _toDisplayString($setup.props.title), 1)
      ])
    ], 2)
  ]))
}