import { computed, provide, ref, createApp, App, watch } from 'vue'
import {
  createRouter, 
  createWebHistory,
  useRoute,
  RouteParams,
  RouteLocationNormalizedLoaded,
  RouteRecordName,
} from 'vue-router'
import { useI18n } from 'vue-i18n'
import * as GVue from 'vue'
import { createPinia } from "pinia"
import createAppRouter from "@/router"
import install from '@/install'
import i18n from "./i18n"
import Plugins from '@/plugins'

import 'wc-page-builder/builder.css'
import 'wc-page-builder/prime.css'
import 'wc-page-builder/container-grid.css'
import 'wc-page-builder/container-grid.css'
import 'wc-page-builder/themes/default.css'

import 'vue-toast-notification/dist/theme-bootstrap.css';
import 'vue-final-modal/style.css'

import PageBuilder from 'wc-page-builder/builder'
import * as GPageBuilder from 'wc-page-builder/builder'
import PageBuilderPrime, { primeRenderers } from 'wc-page-builder/prime'

import PrimeVue from 'primevue/config'
import { definePreset } from '@primevue/themes'
import Aura from '@primevue/themes/aura'

// This global vars are required for accordion plugin to work.
(window as any).Vue = GVue;
(window as any).WcPageBuilder = GPageBuilder;

import Components from "@/components"
import Modules from "@/modules"

type CreateApplication = {
  createApp: typeof createApp
  createRouter: typeof createRouter
  createWebHistory: typeof createWebHistory
  createPinia: typeof createPinia
}

export function createApplication({
  createApp,
  createRouter: createRouterInstance,
  createWebHistory,
  createPinia,
}: CreateApplication): App {
  const app = createApp({
    setup() {
      const route: RouteLocationNormalizedLoaded = useRoute()
      const { t: $t } = useI18n()
      const updatedTitle = ref<string | null>(
        null
      )

      const currentTitle = computed(() => {
        if (updatedTitle.value) {
          return updatedTitle.value
        }
        return 'function' === typeof route.meta.title
          ? route.meta.title?.(route.params as RouteParams) ||
              null
          : route.meta.title || null
      })

      provide('currentTitle', currentTitle)
      provide(
        'updateCurrentTitle',
        (title: string | null) => {
          console.log(title, 'updateCurrentTitle');
          updatedTitle.value = title
        }
      )
      return {}
    },
  })

  app.config.compilerOptions.delimiters = ['[[', ']]']

  const pinia = createPinia()

  const router = createAppRouter({
    app,
    createInstance: createRouterInstance,
    createWebHistory,
    pinia,
  })

  app
    .use(install)
    .use(pinia)
    .use(i18n)
    .use(router)
    .use(Modules)
    .use(Components)
    .use(PrimeVue, { theme: { preset: Aura } })
    .use(PageBuilder, {
      renderers: primeRenderers,
    })
    .use(PageBuilderPrime)
    .use(Plugins)
    .mount('#app')

  return app
}
