import { App } from 'vue'
import { accessMeta } from '@/packages/vue-router-permissions'
import { route, prefixRoutes } from '@/router/utils'
import { RouteRecordRaw } from 'vue-router'
import { createRoutes as createProtocolsRoutes, PROTOCOLS_LIST_PAGE_NAME } from './modules/protocols/router'
import { createRoutes as createProtocolsTrashRoutes } from './modules/trash-protocols/router'
import { createRoutes as createPageNotFoundRoutes, PAGE_NOT_FOUND_PAGE_NAME } from './modules/404/router'
import { createRoutes as createAccessDeniedRoutes } from './modules/403/router'
import { createRoutes as createTagsRoutes } from './modules/tags/router'
import { createRoutes as createTagsTrashRoutes } from './modules/trash-tags/router'
import { createRoutes as createSourcesRoutes } from './modules/sources/router'
import { createRoutes as createQuestionsRoutes } from './modules/questions/router'
import { createRoutes as createAttachmentsRoutes } from './modules/attachments/router'
import { createRoutes as createTrashAttachmentsRoutes } from './modules/trash-attachments/router'
import { createRoutes as createProfileRoutes } from './modules/profile/router'
import { createRoutes as createNotificationsRoutes } from './modules/notifications//router'
import { allPermissions } from '@access/index'
import { accessPermission, } from '@access/permissions'
import { prefixLanguage } from '@utils/urls'
// const textPage = () => import('./modules/text-page/text-page.vue')
const Cabinet = () => import('./views/Cabinet.vue')
const Base = () => import('./views/Base.vue')

const {
  STAFF: {
    PROTOCOL,
    PROTOCOL_SOURCE,
    TAG,
    PROTOCOL_ATTACHMENT,
    PROTOCOL_QUESTION,
    TRASH: { 
      VIEW_PROTOCOL: VIEW_PROTOCOL_TRASH,
      VIEW_PROTOCOL_ATTACHMENT: VIEW_PROTOCOL_ATTACHMENT_TRASH,
      VIEW_TAG: VIEW_TAG_TRASH,
      VIEW_USER: VIEW_USER_TRASH
    },
    USER
  },
} = allPermissions

export const BASE_PAGE_NAME = 'cabinet:page'
export const BASE_PAGE_URL = prefixLanguage('/cabinet/')
console.log("🚀 ~ BASE_PAGE_URL:", BASE_PAGE_URL)

export function createRoutes(): RouteRecordRaw[]  {
  return [
    route(BASE_PAGE_URL, Cabinet, BASE_PAGE_NAME, {
      children: [
        prefixRoutes('procedures/', [
          prefixRoutes('protocols/', createProtocolsRoutes(),
            { meta: { ...accessPermission(PROTOCOL), withLink: true } }
          ),
          prefixRoutes('sources/', createSourcesRoutes(), 
          { meta: { ...accessPermission(PROTOCOL_SOURCE), withLink: true } }
          ),
          prefixRoutes('files/', createAttachmentsRoutes(),
          { meta: { ...accessPermission(PROTOCOL_ATTACHMENT), withLink: true } }
          ),
          prefixRoutes('tags/', createTagsRoutes(), 
          { meta: { ...accessPermission(TAG), withLink: true } }
        ),
        ], { meta: { withLink: false } }),
        prefixRoutes('conversations/', [
          prefixRoutes('protocols/', createQuestionsRoutes(), 
          { meta: { ...accessPermission(PROTOCOL_QUESTION),withLink: true } }
          ),

        ], { meta: { withLink: false } }),
        prefixRoutes('trash/', [
          prefixRoutes('protocols/', createProtocolsTrashRoutes(),
          { meta: { ...accessPermission(VIEW_PROTOCOL_TRASH), trash: true, withLink: true } }
          ),
          prefixRoutes('files/', createTrashAttachmentsRoutes(),
            { meta: { ...accessPermission(VIEW_PROTOCOL_ATTACHMENT_TRASH), trash: true, withLink: true } }
          ),
          prefixRoutes('tags/', createTagsTrashRoutes(), 
            { meta: { ...accessPermission(VIEW_TAG_TRASH), trash: true, withLink: true } }
          ),
        ], { meta: { withLink: false } }),   
        
        prefixRoutes('profile/', createProfileRoutes(), 
          { meta: { ...accessPermission(USER) } }
        ),
        prefixRoutes('notifications/', createNotificationsRoutes(), 
          { meta: { ...accessPermission(USER) } }
        ),
        prefixRoutes('not-found/', createPageNotFoundRoutes()),
        prefixRoutes('forbidden/', createAccessDeniedRoutes()),
        {
          path: prefixLanguage('/cabinet/:pathMatch(.*)*'),
          redirect: { name: PAGE_NOT_FOUND_PAGE_NAME }
        },
        // route('info/:slug/', textPage, 'text-page', {}),
      ],
      redirect: { name: PROTOCOLS_LIST_PAGE_NAME },
    }),
  ]
}
